/**
 * 佣金管理-佣金信息
 * luxinwen
 * 2023-03-22
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_COMMISSION_MGM_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.buildingName" placeholder="请输入项目" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.activityName" placeholder="请输入活动" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.customerName" placeholder="请输入被推荐人" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.phone" placeholder="请输入手机号" clearable />
          </FormItem>
          <FormItem>
            <DatePicker class="width-m" v-model="formData.recommendTime" type="datetimerange" placeholder="请选择推荐时间" />
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.commissionStatus" placeholder="请选择结算状态" clearable>
              <Option v-for="(value, key) in statusList" :key="'status' + key" :value="key">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="commissionStatus">
            <span>{{ statusList[params.row.commissionStatus] }}</span>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="showData(params.row)" v-grant="'OSP_COMMISSION_MGM_VIEW'">查看</Button>
            <Button @click="editData(params.row, params.row.commissionStatus === 1 ? 2 : 1)" v-show="params.row.commissionStatus === 0 || params.row.commissionStatus === 1" v-grant="'OSP_COMMISSION_MGM_UPDATE'">佣金结算</Button>
            <Button @click="submitUpdate(params.row, 3)" v-show="params.row.commissionStatus !== 3" v-grant="'OSP_COMMISSION_MGM_UNUSUAL'">异常</Button>
            <Button @click="submitUpdate(params.row, 0)" v-show="params.row.commissionStatus === 3" v-grant="'OSP_COMMISSION_MGM_RECOVER'">恢复</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <Modal v-model="updateModal.display" title="佣金结算" width="700" :mask-closable="false">
      <Form :model="updateModal" :label-width="130">
        <FormItem label="结算状态变更为">
          {{ statusList[updateModal.commissionStatus] }}
        </FormItem>
        <FormItem label="佣金金额" required v-if="updateModal.commissionStatus === 1">
          <InputNumber class="width-s" v-model="updateModal.commissionAmount" :min="0" :max="99999999" />
        </FormItem>
        <FormItem label="备注信息">
          <Input v-model.trim="updateModal.remark" type="textarea" maxlength="100" clearable />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" size="large" @click="updateModal.display = false">取消</Button>
        <Button type="primary" size="large" :loading="updateModal.loading" @click="submitUpdate">确定</Button>
      </div>
    </Modal>

    <info v-model="infoDisplay" :data="infoData"></info>
  </div>
</template>

<script>
  import { COMMISSION_STATUS } from '@/util/enum';
  import info from './info.vue';

  export default {
    components: {
      info
    },
    data() {
      return {
        formData: {
          activityName: '', // 活动名称
          buildingName: '', // 项目名称
          commissionStatus: null, // 佣金发放状态：0待发放/1发放中/2已发放/3发放异常
          customerName: '', // 客户姓名
          phone: '',  // 客户手机号
          recommendTime: [] // 推荐时间
        },
        tableColumns: [
          {
            title: '项目',
            key: 'buildingName'
          },
          {
            title: '活动',
            key: 'activityName'
          },
          {
            title: '被推荐人',
            key: 'customerName'
          },
          {
            title: '被推荐人手机号码',
            key: 'phone'
          },
          {
            title: '推荐日期',
            key: 'recommendTime'
          },
          {
            title: '佣金金额（元）',
            key: 'commissionAmount'
          },
          {
            title: '结算状态',
            slot: 'commissionStatus'
          },
          {
            title: '操作',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        statusList: COMMISSION_STATUS,
        infoDisplay: false,
        infoData: {},
        updateModal: {
          display: false,
          loading: false,
          id: '',
          commissionStatus: '',
          commissionAmount: null,
          remark: ''
        }
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_COMMISSION_MGM_VIEW, this.$grant.OSP_COMMISSION_MGM_UPDATE, this.$grant.OSP_COMMISSION_MGM_UNUSUAL, this.$grant.OSP_COMMISSION_MGM_RECOVER];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        if (this.formData.recommendTime.length > 1) {
          data.startRecommendTime = this.formatDate(this.formData.recommendTime[0]);
          data.endRecommendTime = this.formatDate(this.formData.recommendTime[1]);
        }
        delete data.recommendTime;
        this.$axios({
          url: this.$api.member.queryCommissionList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 查看数据
       */
      showData(row) {
        this.infoData = row;
        this.infoDisplay = true;
      },
      editData(row, commissionStatus) {
        this.updateModal.id = row.id;
        this.updateModal.commissionStatus = commissionStatus;
        this.updateModal.commissionAmount = null;
        this.updateModal.remark = '';
        this.updateModal.display = true;
      },
      submitUpdate(row, status) {
        let tips;
        let text;
        let data;
        if (status === 3) {
          tips = '确定要设置为异常吗？';
          text = '操作成功';
          data = {
            customerId: row.id,
            commissionStatus: status,
            remark: ''
          };
        } else if (status === 0) {
          tips = '确定要设置恢复吗？';
          text = '操作成功';
          data = {
            customerId: row.id,
            commissionStatus: status,
            remark: ''
          };
        } else {
          tips = '确定要提交佣金结算吗？';
          text = '佣金结算成功';
          data = {
            customerId: this.updateModal.id,
            commissionStatus: this.updateModal.commissionStatus,
            remark: this.updateModal.remark
          };
          if (this.updateModal.commissionStatus === 1) {
            if (!this.updateModal.commissionAmount) {
              this.notice('请先输入佣金金额');
              return;
            }
            data.commissionAmount = this.updateModal.commissionAmount;
          }
        }
        this.$confirm(tips).then(() => {
          this.$axios({
            url: this.$api.member.updateCommission,
            data,
            beforeSend: () => {
              this.updateModal.loading = true;
            },
            complete: () => {
              this.updateModal.loading = false;
            }
          }).then(data => {
            this.notice({
              type: 'success',
              desc: text
            });
            this.updateModal.display = false;
            this.getData();
          });
        });
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>